import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, get } from 'lodash';
import { getIsWriteAllAllowed } from '../../../store/user/user-reducer';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { createVendor } from '../../vendors/vendors-actions';
import { getAllVendors } from '../../vendors/vendors-selectors';

function VendorDetails({ modalData }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const isEditAllowed = useSelector(getIsWriteAllAllowed);
  const vendors = useSelector(getAllVendors);
  const [inputValue, setInputValue] = useState<any>('');
  const [inputList, setInputList] = useState<any>([]);

  useEffect(() => {
    if (vendors?.length) {
      setInputList(vendors);
    }
  }, [vendors]);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const formRef: any = React.createRef();

  const onSubmit = (formData: any) => {
    if (inputList.some((item) => item.name === inputValue)) {
      formRef.current.setFields([
        {
          name: 'name',
          errors: ['This value is already entered!'],
        },
      ]);
    } else {
      // Add the value to the inputList and reset the error
      const payload = {
        id: get(modalData, 'id', null),
        name: formData?.name,
        isDefault: formData?.isDefault !== undefined ? formData?.isDefault : false,
        active: formData?.active !== undefined ? formData?.active : true,
      };
      dispatch(createVendor(payload));
      modalData.onClose();
      setInputValue('');
    }
  };

  return (
    <Form
      form={form}
      className='region-district-details-form'
      layout='vertical'
      onValuesChange={() => setIsDisabled(false)}
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      ref={formRef}
      initialValues={{ name: modalData.name, isDefault: modalData.isDefault, active: modalData.active }}
    >
      <Form.Item key='name' label='Name' name='name' rules={[{ required: true, message: `Please enter vendor name` }]}>
        <Input onChange={handleInputChange} />
      </Form.Item>
      <Form.Item key='isDefault' label='isDefault' name='isDefault' valuePropName='checked'>
        <Checkbox name='isDefault'></Checkbox>
      </Form.Item>
      <Form.Item key='active' label='active' name='active' valuePropName='checked'>
        <Checkbox value={true} defaultChecked={true}></Checkbox>
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || isDisabled}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default VendorDetails;
