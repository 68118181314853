/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Button, Checkbox, Collapse, Drawer, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ExpandIcon from '../common/ExpandIcon';
import { getActiveVendors, getAllVendors } from './vendors-selectors';
import { createVendor } from './vendors-actions';
import styles from './Styles.module.scss';
import React from 'react';

interface IEditVendorDetailsProps {
  id: any;
  vendorId?: string;
  record: any;
  setRowDetail: any;
  setSelectedVendor: any;
  hideDetails?: any;
  setVendorDrawer: any;
  vendorDrawer: any;
}

function EditVendorDetails({
  id,
  vendorId,
  record,
  setRowDetail,
  setSelectedVendor,
  hideDetails,
  setVendorDrawer,
  vendorDrawer,
}: IEditVendorDetailsProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const name = 'vendor-details';
  const classNameForm = `${name}-form`;
  const { Panel } = Collapse;
  const [isDisabled, setIsDisabled]: any = useState(true);
  const disCheck = !isDisabled ? false : true;
  const vendors = useSelector(getAllVendors);
  const formRef: any = React.createRef();
  const [inputValue, setInputValue] = useState<any>('');
  const [inputList, setInputList] = useState<any>([]);

  useEffect(() => {
    if (vendors?.length) {
      setInputList([...vendors]);
    }
  }, [vendors]);
  useEffect(() => {
    if (vendorId) {
      const details = {
        name: record?.name,
        isDefault: record?.isDefault,
        active: record?.active,
      };
      form.setFieldsValue(details);
    }
  }, [vendorId]);
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const onSubmit = (formData) => {
    if (inputList.some((item) => item.name === inputValue)) {
      formRef.current.setFields([
        {
          name: 'name',
          errors: ['This value is already entered!'],
        },
      ]);
    } else {
      const { name, isDefault, active } = formData;
      let payload: any = {
        name: name,
        isDefault: isDefault ?? false,
        active: active ?? false,
      };
      if (vendorId) {
        payload = {
          id: record?.id,
          ...payload,
        };
      }
      dispatch(createVendor(payload));
      setIsDisabled(true);
      form.resetFields();
      hideDetails();
    }
  };
  return (
    <Drawer
      placement='right'
      open={vendorDrawer}
      width={335}
      closable={false}
      className='drawer-panel'
      forceRender={true}
      onClose={() => {
        setVendorDrawer(false);
        form.resetFields();
        setSelectedVendor(null);
        setRowDetail({});
      }}
    >
      <div className={styles['details-panel']}>
        <div className={styles['details-panel-header']}>
          <span>UPDATE VENDOR</span>
          <Button
            type={'ghost'}
            className={styles['details-panel-btn']}
            onClick={() => {
              hideDetails();
              form.resetFields();
            }}
          >
            CLOSE ALL &#10006;
          </Button>
        </div>
        <Collapse
          destroyInactivePanel
          defaultActiveKey={1}
          bordered={true}
          expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
          className='med-custom-collapse'
          ghost={true}
        >
          <Panel header='VENDOR MANAGEMENT' key={1} className='med-custom-panel'>
            <Form
              form={form}
              name={name}
              layout='vertical'
              onFinish={onSubmit}
              autoComplete='off'
              className={classNameForm}
              ref={formRef}
              onValuesChange={() => setIsDisabled(false)}
            >
              <Form.Item
                key='name'
                label={'Vendor'}
                name='name'
                className='label-text'
                rules={[{ required: true, message: 'Please select vendor' }]}
              >
                <Input name='name' onChange={handleInputChange} />
              </Form.Item>
              <Form.Item key='isDefault' label='isDefault' name='isDefault' valuePropName='checked'>
                <Checkbox name='isDefault'></Checkbox>
              </Form.Item>
              <Form.Item key='active' label='active' name='active' valuePropName='checked'>
                <Checkbox></Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' disabled={disCheck} className={'btn-form-action'}>
                  SAVE
                </Button>
              </Form.Item>
            </Form>
          </Panel>
        </Collapse>
      </div>
    </Drawer>
  );
}

export default EditVendorDetails;
