import { Button, Form, Select, Input, Table, Space, Tooltip, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVendorForLocation } from '../../offices/offices-selectors';
import { deleteVendor, saveVendorAccount } from '../../offices/offices-actions';
import { getWriteTabsPermissions } from '../../../store/user/user-reducer';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import EditableCell, { EditableRow } from '../../grid/EditableCell';
import { getIsDefaultFalseVendors, getIsDefaultTrueVendors } from '../../vendors/vendors-selectors';
import { SuffixIcon, TagRenderer } from '@med-fe/ui';
import { some } from 'lodash';
import { showErrorNotification } from '@med-fe/util';

function VendorDetails({ locId }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const data: any = useSelector((state) => getVendorForLocation(state, locId));
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDefaultFalse, setIsDefaultFalse] = useState<any>(data?.defaultVendorFalse);
  const [isDefaultTrue, setIsDefaultTrue] = useState<any>([]);
  const isEditAllowed = useSelector(getWriteTabsPermissions).vendor;
  const falseVendors = useSelector(getIsDefaultFalseVendors);
  const trueVendors = useSelector(getIsDefaultTrueVendors);
  const [vendor, setVendor] = useState(falseVendors);
  const [payload, setPayload] = useState<any>([]);
  const [isFieldSet, setIsFieldSet] = useState(false);
  const [defaultTrueData, setDefaultTrueData] = useState<any>();

  useEffect(() => {
    const mergedArray = Array.from(
      new Map([...trueVendors, ...data?.defaultVendorTrue].map((item: any) => [item.key, item])).values()
    ).filter((obj: any) => obj !== false);
    setIsDefaultTrue(mergedArray);
  }, []);

  useEffect(() => {
    if (form.getFieldsValue()?.items?.[0]?.list?.length || payload?.length) {
      setIsFieldSet(true);
    } else {
      setIsFieldSet(false);
    }
  }, [form.getFieldsValue()?.items?.[0]?.list?.length, payload]);

  useEffect(() => {
    form.setFieldsValue({
      ...data,
    });
  }, [locId]);

  useEffect(() => {
    const mergedArray = Array.from(
      new Map([...isDefaultFalse, ...payload].map((item) => [item.vendorId, item])).values()
    );
    const vendors = falseVendors?.filter(
      (ele: any, ind: any) => !mergedArray?.some((elem: any) => ele.id === elem.vendorId && ele.name !== undefined)
    );
    setVendor(vendors);
  }, [payload]);
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter
    }
  };

  const onSubmit = (formData: any) => {
    const listValues: [] = falseVendors
      ?.map((ele: any) => {
        const matchedItem = formData?.items?.[0]?.list?.find((item: any) => {
          return item?.name === ele.id && item?.name;
        });
        if (matchedItem) {
          return {
            id: null,
            vendorId: ele.id,
            locationId: data?.locationId,
            accountNumber: matchedItem?.accountNumber,
            defaultVendor: ele?.isDefault,
            name: ele.name,
            opType: 'A',
          };
        }
        return null;
      })
      .filter((obj: any) => obj?.vendorId !== null && obj?.vendorId !== undefined);
    const mergedArray = Array.from(
      new Map([...payload, ...listValues].map((item: any) => [item.vendorId, item])).values()
    );

    if (payload?.length) {
      dispatch(saveVendorAccount(mergedArray));
      setPayload([]);
    } else {
      showErrorNotification('Please modify atleast one fields');
    }
  };

  const columns = [
    {
      title: 'Vendor',
      dataIndex: 'name',
      key: 'vendorName',
      width: 140,
    },
    {
      title: 'Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      width: 140,
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      render: (_: any, record: any) => {
        return (
          <Tooltip placement='top' title='Delete'>
            <Button
              size='small'
              type={'primary'}
              icon={<DeleteOutlined />}
              onClick={() => deleteModal(record)}
              disabled={!(record?.vendorAccountId && record?.accountNumber !== 'Enter')}
              children={undefined}
            ></Button>
          </Tooltip>
        );
      },
    },
  ];

  const onSaveChanges = async (record: { targetValue: any; record: { key: any } }) => {
    const updateArr = isDefaultTrue?.map(
      (item: { key: any; vendorAccountId?: any; isDefault?: boolean; name?: string }) => {
        if (item?.key === record?.record?.key) {
          // If found, return the updated data
          return {
            id: item?.vendorAccountId ?? null,
            vendorId: item?.key,
            key: item?.key,
            locationId: data?.locationId,
            defaultVendor: item?.isDefault,
            accountNumber: record.targetValue,
            name: item?.name,
            vendorAccountId: item?.vendorAccountId,
            isDefault: item?.isDefault,
            opType: 'A',
          };
        }
        return item; // Return the original item if not found
      }
    );

    const records = updateArr?.filter((item: { opType?: string }) => item?.opType === 'A') || [];
    setPayload((prevPayload: any) => [...prevPayload, ...records]);
    setIsDefaultTrue(updateArr);
  };

  // Filter Columns with Search Props
  const filterColumn: any = columns.map((column: any) => {
    return column.dataIndex === 'accountNumber'
      ? {
          ...column,
          onCell: (record: any, index: any) => ({
            inputType: column.dataIndex,
            dataIndex: column.dataIndex,
            title: column.title,
            editable: column.filtered,
            onSaveChanges,
            record,
            index,
          }),
        }
      : { ...column };
  });
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const deleteModal = (record) => {
    if (record?.isDefault) {
      const updatedArray = isDefaultTrue?.map((item: any) => {
        if (item?.key === record?.key) {
          return { ...item, accountNumber: 'Enter' };
        }
        return item;
      });
      setIsDefaultTrue(updatedArray);
      dispatch(deleteVendor(record));
    } else {
      const updatedArray = isDefaultFalse?.filter((item) => item.vendorId !== record.vendorId);
      setIsDefaultFalse(updatedArray);
      const mergedArray = Array.from(
        new Map([...payload, ...updatedArray].map((item: any) => [item.vendorId, item])).values()
      );
      setPayload(mergedArray);
      dispatch(deleteVendor(record));
    }
  };

  const handleAddMoreAction = (addedRecords: any, type) => {
    if (type === 'add') {
      const listValues: [] = falseVendors
        ?.map((ele: any) => {
          const matchedItem = form.getFieldsValue()?.items?.[0]?.list?.find((item: any) => {
            return item?.name === ele.id;
          });
          if (matchedItem) {
            return {
              id: null,
              vendorId: ele.vendorId,
              locationId: data?.locationId,
              accountNumber: matchedItem?.accountNumber,
              defaultVendor: ele?.isDefault,
              name: ele.name,
              opType: 'A',
            };
          }
          return null;
        })
        .filter((obj: any) => obj !== null);
      setPayload([...payload, ...listValues]);
    } else {
      const updatedArray = payload?.filter((item) => some((ele: any) => item.vendorId !== ele.vendorId));
      const mergedArray = Array.from(
        new Map([...payload, ...updatedArray].map((item: any) => [item.vendorId, item])).values()
      );
      setPayload(mergedArray);
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      className='vendor-details-form'
      onFinish={(formData) => {
        onSubmit(formData);
        setIsDisabled(true);
      }}
      onValuesChange={() => setIsDisabled(false)}
      autoComplete='off'
      initialValues={{ items: [{}] }}
      onKeyPress={handleKeyPress}
    >
      <Card>
        <Table
          key='key'
          columns={filterColumn}
          dataSource={isDefaultTrue}
          bordered
          pagination={false}
          style={{ marginBottom: '5px' }}
          components={components}
          scroll={{ y: 'calc(70vh - 17.5em)' }}
          rowClassName={() => 'editable-row'}
        ></Table>
      </Card>
      <Card>
        <Table
          key='vendorId'
          columns={columns}
          dataSource={isDefaultFalse}
          bordered
          pagination={false}
          scroll={{ y: 'calc(70vh - 17.5em)' }}
        ></Table>
        <Form.List name='items'>
          {(fields, { add, remove }) => (
            <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column', marginTop: '10px' }}>
              {fields.map((field) => (
                <>
                  <Form.Item label=' '>
                    <Form.List name={[field.name, 'list']}>
                      {(subFields, subOpt) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 16,
                          }}
                        >
                          {subFields.map((subField) => (
                            <Space key={subField.key}>
                              <Form.Item noStyle name={[subField.name, 'name']}>
                                <Select
                                  style={{ width: '160px' }}
                                  showSearch={true}
                                  allowClear={true}
                                  placeholder='Select name'
                                  optionFilterProp='children'
                                  filterOption={(input, option) => {
                                    return (
                                      option?.children !== undefined &&
                                      (option!?.children as unknown as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    );
                                  }}
                                >
                                  {vendor?.map((item, index) => {
                                    return (
                                      <Option key={index} label={item?.name} value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                              <Form.Item noStyle name={[subField.name, 'accountNumber']}>
                                <Input placeholder='accountNumber' />
                              </Form.Item>
                              <CloseOutlined
                                onClick={() => {
                                  subOpt.remove(subField.name);
                                  handleAddMoreAction(form.getFieldsValue, 'remove');
                                }}
                              />
                            </Space>
                          ))}
                          <Button
                            type='dashed'
                            onClick={() => {
                              subOpt.add();
                              handleAddMoreAction(form.getFieldsValue, 'add');
                            }}
                            block
                          >
                            + Add Item
                          </Button>
                        </div>
                      )}
                    </Form.List>
                  </Form.Item>
                </>
              ))}
            </div>
          )}
        </Form.List>
      </Card>

      <Form.Item>
        <Button type='primary' htmlType='submit' className={'btn-form-action'} disabled={!isEditAllowed || !isFieldSet}>
          SAVE
        </Button>
      </Form.Item>
    </Form>
  );
}

export default VendorDetails;
