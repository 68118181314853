import { VENDORS_STATE_KEY } from './vendors-reducer';

export const getSearch = (state) => state[VENDORS_STATE_KEY].search;
export const getSorting = (state) => state[VENDORS_STATE_KEY].sorting;
export const getImportLoading = (state: any) => state[VENDORS_STATE_KEY].importLoading;
export const getImportValidationData = (state: any) => state[VENDORS_STATE_KEY].importValidationData;
export const getTotalCount = (state: any) => state[VENDORS_STATE_KEY].totalCount;
export const getSuccessErrorReport = (state: any) => state[VENDORS_STATE_KEY].successErrorReport;
export const getVendorById = (state: any, id: string) =>
  state[VENDORS_STATE_KEY].vendor.contents.find((vendor) => vendor.id === id);

export const getVendorMaintenanceForGrid = (state) =>
  state[VENDORS_STATE_KEY]?.vendor?.contents?.map(({ id, ...rest }, idx) => ({
    key: `${id}-${idx}`,
    id: id,
    ...rest,
  }));

export const getTotal = (state) => state[VENDORS_STATE_KEY].vendor.total;
export const getLoading = (state) => state[VENDORS_STATE_KEY].loading;
export const getDetailsLoading = (state) => state[VENDORS_STATE_KEY].detailsLoading;
export const getRemoveLoading = (state) => state[VENDORS_STATE_KEY].removeLoading;
export const getActiveVendors = (state) =>
  state[VENDORS_STATE_KEY].vendorDetails?.map((vendor) => vendor.active === true && vendor);
export const getAllVendors = (state) => state[VENDORS_STATE_KEY].vendorDetails;
export const getIsDefaultFalseVendors = (state: any) =>
  state[VENDORS_STATE_KEY].vendorDetails.map((vendor) => vendor.isDefault === false && vendor);
export const getIsDefaultTrueVendors = (state: any) =>
  state[VENDORS_STATE_KEY].vendorDetails.map(
    (vendor) => vendor.isDefault === true && { ...vendor, key: vendor.id, accountNumber: 'Enter' }
  );
