import { useDispatch, useSelector } from 'react-redux';
import { getActiveVendors, getVendorMaintenanceForGrid } from '../../components/vendors/vendors-selectors';
import { useEffect } from 'react';
import { office_vendor_columns } from '../../components/grid/grid-configs/officeVendor';
import { fetchVendors } from '../../components/vendors/vendors-actions';

const useVendorColumns = () => {
  const dispatch = useDispatch();
  const vendorColumns: any = [];
  useEffect(() => {
    dispatch(fetchVendors({}));
  }, []);
  const vendors: any = useSelector(getActiveVendors);
  vendors?.map((item: any) => {
    const itemDetails = {
      title: item?.name,
      dataIndex: `${item?.name}_acc`,
      key: item?.name,
      width: 100,
    };
    vendorColumns?.push(itemDetails);
  });
  return [...office_vendor_columns, ...vendorColumns];
};
export default useVendorColumns;
